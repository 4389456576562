// Load Styles
import '../scss/main.scss';

// Load Bootstrap init
import { initBootstrap } from "./bootstrap.js";

// Loading bootstrap with optional features
initBootstrap({
  tooltip: false,
  popover: false,
  toasts: false,
});

// Your app code

const nav = document.querySelector('#nav');

window.addEventListener('scroll', function () {
  if (window.scrollY > 0) {
    nav.classList.add("scroll");
  }
  else {
    nav.classList.remove("scroll");
  }
});


// Paddle Logic
if (process.env.PADDLE_PRODUCTION == "false") {
  // Sandbox
  Paddle.Environment.set('sandbox');
}

Paddle.Setup({ vendor: parseInt(process.env.PADDLE_VENDOR_ID), });

function checkoutMonthly() {
  Paddle.Checkout.open({ product: parseInt(process.env.PADDLE_PRODUCT_MONTHLY), quantity: 6 });
}
function checkoutYearly() {
  Paddle.Checkout.open({ product: parseInt(process.env.PADDLE_PRODUCT_YEARLY), quantity: 6 });
}

const buyButtonMonthly = document.getElementById('buy-monthly');
buyButtonMonthly.addEventListener('click', checkoutMonthly);
const buyButtonYearly = document.getElementById('buy-yearly');
buyButtonYearly.addEventListener('click', checkoutYearly);


function languageRedirect() {

  // check if language was set in local storage
  const languageStorage = localStorage.getItem('language');
  if (!languageStorage) {
    const language = navigator.language || navigator.userLanguage;

    if (language.startsWith('de') && window.location.pathname.indexOf('/de') == -1) {
      setLanguage('de');
    }

    if (!language.startsWith('de')) {
      setLanguage('en');
    }
  }
  else {
    if (languageStorage == 'de' && window.location.pathname.indexOf('/de') == -1) {
      setLanguage('de');
    }
    // don't redirect to en if /de is called directly
  }
}
languageRedirect();

function setLanguage(e, language) {
  e.preventDefault();

  localStorage.setItem('language', language);

  if (language == 'de') {
    window.location.href = '/de';
  }
  else {
    window.location.href = '/';
  }
}

const langButton = document.getElementById('set-language');
const lang = langButton.dataset.lang;
langButton.addEventListener('click', (e) => setLanguage(e, lang));
