import * as bootstrap from "bootstrap";

export const initBootstrap = function (config) {
  // Enabling tooltips
  if (config.tooltip) {
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))

    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl)
    })
  }

  // Enabling popovers
  if (config.popover) {
    const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))

    popoverTriggerList.map(function (popoverTriggerEl) {
      return new bootstrap.Popover(popoverTriggerEl, {})
    })
  }

  // Enabling toasts
  if (config.toasts) {
    const toastTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="toast"]'))

    toastTriggerList.map(function (toastTriggerEl) {
      // Define the target property
      let toastTarget = null

      if ("A" === toastTriggerEl.nodeName) {
        toastTarget = toastTriggerEl.href || null

        if (toastTarget.includes('#')) {
          toastTarget = `#${toastTarget.split("#").pop()}`
        } else {
          return
        }
      } else if ("BUTTON" === toastTriggerEl.nodeName) {
        toastTarget = toastTriggerEl.dataset.bsTarget || null
      }

      // Check if the target exists
      const toastTargetEl = document.querySelector(toastTarget);

      if (!toastTargetEl) {
        return
      }

      // Initialize toast
      const toast = new bootstrap.Toast(toastTargetEl, {})

      // Add click even to trigger
      toastTriggerEl.addEventListener("click", function (event) {
        event.preventDefault();
        toast.show()
      })
    })
  }



  // Enabling accordion - carousel interaction
  document.querySelectorAll('.carousel-control-next').forEach(carouselNext => {
    carouselNext.addEventListener('click', event => {
      document.querySelectorAll('.accordion-collapse.show').forEach(accordeonItem => {
        new bootstrap.Collapse(accordeonItem).hide();

        // get parent .accordion-item, find next .accordion-item, find .accordion-collapse, show it
        const parent = accordeonItem.closest('.accordion-item');
        let next = parent.nextElementSibling;

        if (next == null) {
          // get first .accordion-item sibling
          next = parent.parentElement.firstElementChild;
        }
        const collapse = next.querySelector('.accordion-collapse');
        new bootstrap.Collapse(collapse).show();
      });
    }
    )
  })

  document.querySelectorAll('.carousel-control-prev').forEach(carouselPrev => {
    carouselPrev.addEventListener('click', event => {
      document.querySelectorAll('.accordion-collapse.show').forEach(accordeonItem => {
        new bootstrap.Collapse(accordeonItem).hide();

        // get parent .accordion-item, find previous .accordion-item, find .accordion-collapse, show it
        const parent = accordeonItem.closest('.accordion-item');
        let prev = parent.previousElementSibling;

        if (prev == null) {
          // get last .accordion-item sibling
          prev = parent.parentElement.lastElementChild;
        }
        const collapse = prev.querySelector('.accordion-collapse');
        new bootstrap.Collapse(collapse).show();
      });
    }
    )
  })

  const carouselFeatures = document.getElementById('carouselFeatures');
  if (carouselFeatures) {
    carouselFeatures.addEventListener('slide.bs.carousel', function (event) {
      // pause all videos in carousel
      const videos = document.querySelectorAll('.carousel-item video');
      videos.forEach(video => {
        video.pause();
        video.currentTime = 0;
      });

      // start video in relatedTarget
      const video = event.relatedTarget.querySelector('video');
      if (video) {
        video.play();
      }
    });
  }

  // play active video in carousel
  const video = document.querySelector('.carousel-item.active video');
  if (video) {
    video.play();
  }

}

